import React from 'react';

import IncDecCounter from "./IncDecCounter";

import '../assets/css/Product1.css';

import CloseButton from "../assets/images/close-button.png";

function ListProductDetails({product = null, index, updateQuantity, removeFromList, parent = null}) {
    if (product) {
        return (
            <div className="request-product-card my-5" key={(parent ? parent : "") + "ProductList" + index} style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F9F9F9",
                padding: "2%",
            }}>
                <div className="col-md-3">
                    <img className="card-img" src={product.image_url} alt={product.name}
                         style={{width: "90%", height: "23vh", borderRadius: "12px", objectFit: "contain"}}/>
                </div>
                <div className="card-body col-md-6">
                    <h5 className="card-title" style={{textAlign: "left", margin: "0"}}>{product.name}</h5>
                    <div className="d-flex flex-row">
                        <div>
                            <div className="form-check form-check-inline" key={index}>
                                <input type="radio" className="form-check-input product-color-black" disabled
                                       name="color" id={"color" + index} value={product.color}
                                       style={{backgroundColor: product.color}}/>
                            </div>
                        </div>
                        <div className="px-5">
                            <p className="card-text" style={{color: "#777777", fontSize: "20px"}}>Size: <span
                                style={{color: "#5C5C5C"}}>{product.size} {product.unit}</span></p>
                        </div>
                    </div>
                    <p className="card-text my-2">{product.product_code}</p>
                    <IncDecCounter updateNumber={updateQuantity} number={product.selectedQuantity}
                                   max={product.quantity} index={index}/>
                </div>
                <div className="col-md-3" style={{alignSelf: "center"}}>
                    <button type="button" className="close-btn" aria-label="Close"
                            style={{textAlign: "center"}} onClick={() => removeFromList(index)}>
                        <img src={CloseButton} alt="Close-Button" className="close-button-img"/>
                    </button>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ListProductDetails;
