import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom';
import {Button, Offcanvas} from 'react-bootstrap';

import Facebook from '../assets/images/Facebook.png';
import Instagram from '../assets/images/Instagram.png';
import Tiktok from '../assets/images/Tiktok.png';
import Phone from '../assets/images/Phone.png';
import Email from '../assets/images/Email.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";

import ListProductDetails from "./ListProductDetails";

import Logo from '../assets/images/logo.svg';
import Add from '../assets/images/ListAdded.png';
import listImage from '../assets/images/list.svg';

import '../assets/css/TopPanel.css';
import '../assets/css/Header.css';

import {loadProductCategoryTags, loadProductsFromTag, updateList} from "../common/Common";

function Header({count = 0, parentUpdateCount}) {
    const [productCategoryTags, setProductCategoryTags] = useState([]),
        [list, setList] = useState(JSON.parse(sessionStorage.getItem('list'))),
        [newProducts, setNewProducts] = useState([]),
        [windowWidth, setWindowWidth] = useState(0),
        location = useLocation();

        const headerSliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            pauseOnHover: true,
            slidesToShow: 7,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

    useEffect(() => {
        loadProductCategoryTags().then(data => {
            setProductCategoryTags(data);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        setList(JSON.parse(sessionStorage.getItem('list')));
    }, [count]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);

    const handleMobileClose = () => setMobileShow(false);
    const handleMobileShow = () => setMobileShow(true);
    const [mobileShow, setMobileShow] = useState(false);

    const productCategoryTagBody = (productCategoryTags) => {
        let body = [];
        productCategoryTags.forEach((productCategoryTag, index) => {
            body.push(
                <div className="home-product" key={"ProductCategoryTag" + index}>
                    <NavLink
                        className={"nav-link" + ((location.state && (location.state.productCategoryTag && location.state.productCategoryTag.id === productCategoryTag.id)) ? " active-category" : "")}
                        to={'/products'}
                        onClick={() => {
                            handleClose();
                            handleMobileClose();
                        }}
                        state={{productCategoryTag: {id: productCategoryTag.id, name: productCategoryTag.name}}}>
                        {productCategoryTag.name}
                        <hr/>
                    </NavLink>
                </div>
            );
        });
        return body;
    };

    const productCategoryTagBodySlide = (productCategoryTags) => {
        let body = [];
        productCategoryTags.forEach((productCategoryTag, index) => {
            body.push(
                <div className="home-product home-productnav d-flex align-items-center justify-content-center" key={"ProductCategoryTag" + index}>
                    <NavLink
                        className={"nav-link" + ((location.state && (location.state.productCategoryTag && location.state.productCategoryTag.id === productCategoryTag.id)) ? " active-category" : "")}
                        to={'/products'}
                        onClick={() => {
                            handleClose();
                            handleMobileClose();
                        }}
                        state={{productCategoryTag: {id: productCategoryTag.id, name: productCategoryTag.name}}}>
                        {productCategoryTag.name}
                        <hr/>
                    </NavLink>
                </div>
            );
        });
        return body;
    }

    const removeFromList = (index) => {
        let tempList = JSON.parse(JSON.stringify(list));
        tempList.splice(index, 1);
        setList(tempList);
        updateList(tempList);
        parentUpdateCount();
    }

    const updateQuantity = (quantity, index) => {
        let tempList = JSON.parse(JSON.stringify(list));
        tempList[index].selectedQuantity = quantity;
        setList(tempList);
        updateList(tempList);
        parentUpdateCount();
    }

    const productNewBody = (products, tag) => {
        const slicedProducts = products.slice(0, 6);
      
        return (
          <div className='grid-containernew'>
            {slicedProducts.map((product, index) => (
                <div key={`product-${index}`} className='grid-itemnew'>
                    <img className='imgnew' src={product.image_url} alt={`Product ${index + 1}`} />

                    <div className="contentlink">
                            <NavLink className={"lnk"} to={'/products'} onClick={handleClose}>
                                Learn More
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                    className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </NavLink>
                    </div>

                </div>
            ))}
          </div>
        );
    };
    
    const productBody = (products) => {
        let body = [];
        if (products) {
            products.forEach((product, index) => {
                body.push(
                    <ListProductDetails product={product} index={index} updateQuantity={updateQuantity}
                                        removeFromList={removeFromList}/>
                );
            });
        }
        return body;
    }

    const formSubmit = (event) => {
        event.preventDefault();
        window.location.href = '/search/' + event.target.search.value;
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
                loadProductsFromTag('New').then(data => {
                    setNewProducts(data);
                }).catch(() => null);
    }, []); 
    const resizeWindow = () => {
        setWindowWidth(window.outerWidth);
    };

    return (
        <>
            <div className="container desktop-header">
                <header className="navbar-light-header">
                    <div className="navbar-expand d-flex flex-row">
                        <div>
                            <a href="https://www.facebook.com/mitshu.lk" target="_blank">
                                <img src={Facebook} style={{width: "25px"}} alt='Facebook'/>
                            </a>
                        </div>
                        <div className="ml-5">
                            <a href="https://instagram.com/mitshuinternational" target="_blank">
                                <img src={Instagram} style={{width: "25px"}} alt='Instagram'/>
                            </a>
                        </div>
                        <div className="ml-5">
                            <a href="https://tiktok.com/" target="_blank">
                                <img src={Tiktok} style={{width: "20px"}} alt='Tiktok'/>
                            </a>
                        </div>
                        <div className="d-flex ml-auto">
                            <div>
                                <img src={Phone} alt=''/>
                                <a href="tel:+9411 2543 451" className="header-txt ml-3" style={{color: "#777777"}}>
                                    +9411 2543 451
                                </a>
                                <span className="header-txt ml-3" style={{color: "#777777"}}>|</span>
                                <a href="tel:+9477 7226 225" className="header-txt ml-3" style={{color: "#777777"}}>
                                    +9477 7226 225
                                </a>
                            </div>
                            <div className=" ml-5">
                                <img src={Email} alt=''/>
                                <a href="mailto:info@mitshu.lk" className="header-txt ml-3" style={{color: "#777777"}}>
                                    info@mitshu.lk
                                </a>
                            </div>
                        </div>

                        <div className="d-flex ml-auto">
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav mr-auto"/>
                                <ul className="navbar-nav d-flex bd-highlight">
                                    <div className="nav-item flex-fill bd-highlight">
                                        <Button onClick={handleShow}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    borderWidth: '0vh',
                                                    boxShadow: "none"
                                                }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="26"
                                                 height="26" alt="Toggler" className="Toggler">
                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" fill="#194389"/>
                                            </svg>
                                        </Button>
                                        <Offcanvas show={show} onHide={handleClose} placement="top"
                                                   style={{backgroundColor: '#FFFFFF', height: '100%'}}>
                                            <Offcanvas.Header closeButton style={{
                                                height: '12%',
                                                backgroundColor: "#FFFFFF",
                                                marginTop: "1%"
                                            }}>
                                                <div style={{marginLeft: '10%'}}>
                                                    <NavLink to="/" onClick={handleClose}>
                                                        <img src={Logo} alt="Logo" id="mobile-logo"/>
                                                    </NavLink>
                                                </div>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body className="d-flex bd-highlight p-0">

                                                <div id="offcanvas-navlinks home-product" className="desktop-offcanvas">
                                                    <div className="d-flex w-100">
                                                        
                                                        <div className="p-2 flex-fill bd-highlight prod">
                                                            <div className="Product-container">
                                                                <h1 className="subtitles-footer">New Arrivals</h1>
                                                                <div className="support-container">
                                                                    <div className="product-cont">
                                                                        <div>
                                                                            {newProducts.length > 0 ?
                                                                                <div className='newpro-menu'>
                                                                                    {productNewBody(newProducts, "New")}
                                                                                </div>
                                                                                : <p className='noproducts text-start' style={{textAlign: "left"}}>No Products Found</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="p-2 flex-fill bd-highlight info">
                                                            <div className="Product-container">
                                                                <h1 className="subtitles-footer">Categories</h1>
                                                                <div className="support-container">
                                                                    <p className="text-start">
                                                                        {productCategoryTagBody(productCategoryTags)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-2 flex-fill bd-highlight info">
                                                            <div className="Information-container">
                                                                <h1 className="subtitles-footer">Information</h1>
                                                                <div className="support-container">
                                                                    <p className="links">
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/about-us"
                                                                                 onClick={handleClose}>
                                                                            <h1 className="links">About Us</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/contact-us"
                                                                                 onClick={handleClose}>
                                                                            <h1 className="links">Contact Us</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/terms-and-conditions"
                                                                                 onClick={handleClose}>
                                                                            <h1 className="links">Terms &
                                                                                Conditions</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/privacy-policy"
                                                                                 onClick={handleClose}>
                                                                            <h1 className="links">Privacy Policy</h1>
                                                                        </NavLink>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <hr style={{border: "1px solid #E0E0E0"}}/>
                </header>
                <div className="container">
                    <div className="big-top-panel">
                        <div className="d-flex flex-row mb-5" style={{margin: "2% 0%"}}>
                            <div className="">
                                <NavLink to="/">
                                    <img src={Logo} alt='Logo'/>
                                </NavLink>
                            </div>
                            <div className="ml-auto">
                                <form className="d-flex search-container justify-content-center" onSubmit={formSubmit}>
                                    <input className="searchbar" placeholder='Search Products Ex: blender' type='search'
                                           id='search' name="search" aria-label="Search" required/>
                                    <button className="btn searchicon btn-lg blue-button" type="submit" value="Submit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             fill="currentColor"
                                             className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div className="ml-auto d-flex justify-content-end">
                                <button className="btn" data-bs-toggle="modal" data-bs-target="#listModal">
                                    <img src={list && list.length > 0 ? Add : listImage} alt='List'/>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Slider className='variable-width' {...headerSliderSettings}>
                            {productCategoryTagBodySlide(productCategoryTags)}
                </Slider>
            </div>
{/*------------------------------------------------------------------------------------mobile menu----------------------------------------------------------------- */} 
            <div className="container mobile-header">
                <header className="navbar-light-header">
                    <div className="navbar-expand d-flex flex-row">
                        <div>
                            <NavLink to="/">
                                <img src={Logo} alt='Logo'/>
                            </NavLink>
                        </div>

                        <div className="d-flex ml-auto">
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav mr-auto"/>
                                <ul className="navbar-nav d-flex bd-highlight">
                                    <div className="nav-item flex-fill bd-highlight">
                                        <Button onClick={handleMobileShow}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    borderWidth: '0vh',
                                                    boxShadow: "none"
                                                }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="26"
                                                 height="26" alt="Toggler" className="Toggler">
                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" fill="#194389"/>
                                            </svg>
                                        </Button>
                                        <Offcanvas show={mobileShow} onHide={handleMobileClose} placement="top"
                                                   style={{backgroundColor: '#FFFFFF', height: '100%'}}>
                                            <Offcanvas.Header closeButton style={{
                                                height: '12%',
                                                backgroundColor: "#FFFFFF",
                                                marginTop: "1%"
                                            }}>
                                                <div style={{marginLeft: '10%'}}>
                                                    <NavLink to="/" onClick={handleMobileClose}>
                                                        <img src={Logo} alt="Logo" id="mobile-logo"/>
                                                    </NavLink>
                                                </div>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body className="d-flex bd-highlight p-0">
                                                <div id="offcanvas-navlinks home-product" className="mobile-offcanvas">
                                                    <div className="d-flex flex-row mb-5" style={{margin: "2% 0%"}}>
                                                        <div>
                                                            <form className="d-flex search-container"
                                                                  onSubmit={formSubmit}>
                                                                <input className="searchbar"
                                                                       placeholder='Search Products Ex: blender'
                                                                       type='search'
                                                                       id='search' name="search" aria-label="Search"
                                                                       required/>
                                                                <button className="btn searchicon btn-lg blue-button"
                                                                        type="submit" value="Submit">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                         height="25"
                                                                         fill="currentColor"
                                                                         className="bi bi-search" viewBox="0 0 16 16">
                                                                        <path
                                                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                                    </svg>
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <button className="btn" data-bs-toggle="modal"
                                                                    data-bs-target="#listModal">
                                                                <img src={list && list.length > 0 ? Add : listImage}
                                                                     alt='List'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex w-100">

                                                    <div className="p-2 flex-fill bd-highlight">
                                                            <div className="Information-container">
                                                                <h1 className="subtitles-footer">Information</h1>
                                                                <div className="support-container">
                                                                    <p className="links">
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/about-us"
                                                                                 onClick={handleMobileClose}>
                                                                            <h1 className="links">About Us</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/contact-us"
                                                                                 onClick={handleMobileClose}>
                                                                            <h1 className="links">Contact Us</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/terms-and-conditions"
                                                                                 onClick={handleMobileClose}>
                                                                            <h1 className="links">Terms &
                                                                                Conditions</h1>
                                                                        </NavLink>
                                                                        <NavLink className="nav-link"
                                                                                 activeClassName="is-active"
                                                                                 to="/privacy-policy"
                                                                                 onClick={handleMobileClose}>
                                                                            <h1 className="links">Privacy Policy</h1>
                                                                        </NavLink>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="p-2 flex-fill bd-highlight">
                                                            <div className="Product-container">
                                                                <h1 className="subtitles-footer">Products</h1>
                                                                <div className="support-container">
                                                                    <p className="text-start">
                                                                        {productCategoryTagBody(productCategoryTags)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
            <div className="modal fade" id="listModal" tabIndex="-1" aria-labelledby="listModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "80%"}}>
                    <div className="modal-content">
                        <div className="modal-header" style={{padding: "2%"}}>
                            <h1 style={{fontSize: "32px"}}>List Items</h1>
                            <p className="VAT-text"
                               style={{margin: "0 0 0 auto"}}>{list ? list.length : 0} Item(s)</p>
                            <button type="button" className="btn btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{margin: "0 0 0 5%"}}/>
                        </div>
                        <div className="modal-body">
                            {list && list.length > 0 ?
                                productBody(list)
                                : <p className='no-products' style={{marginTop: 0}}>No Products Added</p>
                            }
                        </div>
                        <div className="modal-footer">
                            <NavLink to="/request-quote">
                                <button type="button" className="btn Pricetag blue-button"
                                        data-bs-dismiss="modal" onClick={handleMobileClose}>
                                    Request Quote
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20"
                                         fill="currentColor"
                                         className="bi bi-tag flip-tag-icon" viewBox="0 0 16 16">
                                        <path
                                            d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                                        <path
                                            d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                                    </svg>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header
