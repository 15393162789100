import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import IncDecCounter from "./IncDecCounter";

import '../assets/css/Product1.css'

import {addProductToList} from "../common/Common";

function ProductDetails({product = null, modal = false, parentUpdateCount}) {
    let [selectedSize, setSelectedSize] = useState(null),
        [selectedUnit, setSelectedUnit] = useState(null),
        [selectedColor, setSelectedColor] = useState(null),
        [quantity, setQuantity] = useState(1),
        [selectedImage, setSelectedImage] = useState(product ? product.image_url : null);

    useEffect(() => {
        if (product) {
            setSelectedImage(product.image_url);
            setSelectedSize(null);
            setSelectedUnit(null);
            setSelectedColor(null);
            setQuantity(1);
        }
    }, [product]);

    const handleQuantityChange = (quantity) => {
        setQuantity(quantity);
    };

    const sizeBody = (sizes, unit) => {
        let body = [];
        sizes.forEach((size, index) => {
            body.push(
                <button key={index}
                        className={(selectedSize === size ? "selected-size-button " : "") + "btn me-2 volume-btn rounded-circle btn-outline-dark"}
                        style={{padding: "2% 4%"}} onClick={() => setSelectedSize(size)}>
                    {size} {unit}
                </button>
            );
        });
        return body;
    }

    const colorBody = (colors) => {
        let body = [];
        colors.forEach((color, index) => {
            body.push(
                <div className="form-check form-check-inline" key={index}>
                    <input type="radio" className="form-check-input product-color-black" required
                           name="color" id={"color" + index} value={color} onChange={() => setSelectedColor(color)}
                           style={{backgroundColor: color}} checked={selectedColor === color}/>
                </div>
            );
        });
        return body;
    }

    const imageBody = (images) => {
        let body = [];
        images.forEach((image, index) => {
            body.push(
                <div className="col" key={"Image" + index} style={{cursor: "pointer", marginBottom: "3%"}}
                     onClick={() => setSelectedImage(image.image_url)}>
                    <img src={image.image_url} alt={product.name} className="product-slider-image"/>
                </div>
            );
        });
        return body;
    }

    const addToList = () => {
        let tempProduct = product;
        tempProduct.selectedQuantity = quantity;
        tempProduct.size = selectedSize ? selectedSize : JSON.parse(product.sizes)[0];
        tempProduct.color = selectedColor ? selectedColor : JSON.parse(product.colors)[0];
        addProductToList(tempProduct);
        parentUpdateCount();
    }

    if (product) {
        return (
            <div className="container product-detail-container">
                <div className="d-flex flex-row gap-5 mb-5">
                    <div className="product-image-container">
                        <div className="d-flex flex-column gap-5 me-5">
                            <div>
                                <img src={selectedImage} alt={product.name} className="product-image"/>
                            </div>
                            <div>
                                <div className="row row-cols-3 product-slider-image-container">
                                    <div className="col" style={{cursor: "pointer", marginBottom: "3%"}}
                                         onClick={() => setSelectedImage(product.image_url)}>
                                        <img src={product.image_url} alt={product.name}
                                             className="product-slider-image"/>
                                    </div>
                                    {imageBody(product.slider_images)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb" style={{backgroundColor: "transparent"}}>
                                <li className="breadcrumb-item">{product.category.product_category_tag.name}</li>
                                <li className="breadcrumb-item">{product.category.name}</li>
                            </ol>
                        </nav>
                        <h1 className="Product-Title mb-4">{product.name}</h1>
                        <h2 className="Product-value mb-5">
                            {product.product_code}
                            <div className="star-container">
                                <div className="rating">
                                    <input type="radio" name="rating" value="5" id="5"
                                           checked={product.rating === 5} disabled/><label
                                    htmlFor="5" style={{cursor: "default"}}>☆</label>
                                    <input type="radio" name="rating" value="4" id="4"
                                           checked={product.rating === 4} disabled/><label
                                    htmlFor="4" style={{cursor: "default"}}>☆</label>
                                    <input type="radio" name="rating" value="3" id="3"
                                           checked={product.rating === 3} disabled/><label
                                    htmlFor="3" style={{cursor: "default"}}>☆</label>
                                    <input type="radio" name="rating" value="2" id="2"
                                           checked={product.rating === 2} disabled/><label
                                    htmlFor="2" style={{cursor: "default"}}>☆</label>
                                    <input type="radio" name="rating" value="1" id="1"
                                           checked={product.rating === 1} disabled/><label
                                    htmlFor="1" style={{cursor: "default"}}>☆</label>
                                </div>
                            </div>
                        </h2>
                        <p className="Product-detail mb-5">{product.description}</p>
                        <div className="d-flex flex-column mb-5">
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <div>
                                        <h5 className="Product-selection me-5">Quantity</h5>
                                    </div>
                                    <div>
                                        <IncDecCounter number={quantity} updateNumber={handleQuantityChange}
                                                       max={product.quantity}/>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <div>
                                        <h5 className="Product-selection me-5">Select Size</h5>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div>
                                            {sizeBody(JSON.parse(product.sizes), product.unit)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="d-flex flex-row gap-5">
                                    <div>
                                        <h5 className="Product-selection">Select Color</h5>
                                    </div>
                                    <div>
                                        {colorBody(JSON.parse(product.colors))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-grid gap-2 d-md-block mb-5 pb-5">
                            <button className="btn btn-lg me-3 Pricetag white-button-blue-border" type="submit"
                                    onClick={addToList} data-bs-dismiss={modal ? "modal" : "none"}>
                                Add to list
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" fill="currentColor"
                                     className="bi bi-journal-check ml-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                    <path
                                        d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                    <path
                                        d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                                </svg>
                            </button>
                            <Link to="/request-quote">
                                <button className="btn btn-lg Pricetag blue-button" type="submit"
                                        onClick={addToList}
                                        data-bs-dismiss={modal ? "modal" : "none"}>
                                    Get a quote
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20"
                                         fill="currentColor"
                                         className="bi bi-tag flip-tag-icon" viewBox="0 0 16 16">
                                        <path
                                            d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                                        <path
                                            d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                                    </svg>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ProductDetails;
