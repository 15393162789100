import React from 'react';
import Lottie from "lottie-react";

import '../assets/css/Loading.css';

import loader from "../assets/images/loading.json";

function Loading() {
    return (
        <div className="container loading">
            <Lottie animationData={loader} loop={true} autoplay={true}
                    style={{width: '150px', height: '150px', margin: 'auto'}}/>
        </div>
    );
}

export default Loading;
