import React, {useEffect, useState} from "react";

import ListProductDetails from "../components/ListProductDetails";
import Loading from "../components/Loading";

import '../assets/css/RequestQuote.css';

import {loadCountries, updateList} from "../common/Common";
import {postRequest} from "../routes/Routes";

function RequestQuotePage({parentUpdateCount, count = 0}) {
    const [list, setList] = useState(null),
        [countries, setCountries] = useState([]),
        [loading, setLoading] = useState(false),
        [success, setSuccess] = useState({}),
        [error, setError] = useState({}),
        [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        let tempList = JSON.parse(sessionStorage.getItem('list'));
        setList(tempList);
        loadCountries().then(data => {
            setCountries(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        let tempList = JSON.parse(sessionStorage.getItem('list'));
        setList(tempList);
    }, [count]);

    const removeFromList = (index) => {
        let tempList = JSON.parse(JSON.stringify(list));
        tempList.splice(index, 1);
        setList(tempList);
        updateList(tempList);
        parentUpdateCount();
    }

    const updateQuantity = (quantity, index) => {
        let tempList = JSON.parse(JSON.stringify(list));
        tempList[index].selectedQuantity = quantity;
        setList(tempList);
        updateList(tempList);
        parentUpdateCount();
    }

    const productBody = (products) => {
        let body = [];
        if (products) {
            products.forEach((product, index) => {
                body.push(
                    <ListProductDetails product={product} index={index} updateQuantity={updateQuantity}
                                        removeFromList={removeFromList} parent={"RequestQuotePage"}/>
                );
            });
        }
        return body;
    }

    const countryBody = (countries) => {
        let body = [];
        countries.forEach((country, index) => {
            body.push(
                <option key={"country" + index} value={country.name.common}>{country.name.common}</option>
            );
        });
        return body;
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        let values = {
            first_name: event.target.firstName.value,
            last_name: event.target.lastName.value,
            address: event.target.address.value,
            city: event.target.city.value,
            postal_code: event.target.postalCode.value,
            country: event.target.country.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            requests: event.target.requests.value,
            notes: event.target.notes.value,
            products: list,
            endpoint: '/api/quote',
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setList([]);
                    updateList([]);
                    parentUpdateCount();
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    if (!loadingContent) {
        return (
            <div style={{backgroundColor: "white", marginTop: "5vh"}}>
                <div className="container">
                    <h1 className="my-5" style={{fontSize: "32px"}}>Request Quote</h1>
                    {/* Request Quote Form */}
                    <form onSubmit={formSubmit}>
                        <div className="form-row">
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="firstName">First Name <span style={{color: "#E1211D"}}>*</span></label>
                                <input type="text" className="form-control" id="firstName" name="firstName" required/>
                            </div>
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="lastName">Last name <span style={{color: "#E1211D"}}>*</span></label>
                                <input type="text" className="form-control" id="lastName" name="lastName" required/>
                            </div>
                        </div>
                        <div className="form-group request-quote">
                            <label htmlFor="address">Home Address <span style={{color: "#E1211D"}}>*</span></label>
                            <input type="text" className="form-control" id="address" name="address" required/>
                        </div>

                        <div className="form-row">
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="city">City <span style={{color: "#E1211D"}}>*</span></label>
                                <input type="text" className="form-control" id="city" name="city" required/>
                            </div>
                            <div className="form-group request-quote col-md-3">
                                <label htmlFor="postalCode">Postal Code <span
                                    style={{color: "#E1211D"}}>*</span></label>
                                <input type="text" className="form-control" id="postalCode" name="postalCode" required/>
                            </div>
                            <div className="form-group request-quote col-md-3">
                                <label htmlFor="Country">Country <span style={{color: "#E1211D"}}>*</span></label>
                                <select className="form-control form-select form-select-sm"
                                        aria-label=".form-select-sm example"
                                        name="country" id="country" required>
                                    <option selected disabled value="">Select Country</option>
                                    {countryBody(countries)}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="Email">Email <span style={{color: "#E1211D"}}>*</span></label>
                                <input type="email" className="form-control" id="Email" name="email" required/>
                            </div>
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="phone">Phone Number <span style={{color: "#E1211D"}}>*</span></label>
                                <input type="phone" className="form-control" id="phone" name="phone" required/>
                            </div>
                        </div>

                        {/* Product Card */}
                        <div className="list-product-container">
                            {productBody(list)}
                        </div>
                        <div className="form-row">
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="requests">Additional Requests</label>
                                <textarea className="form-control" id="requests" name="requests"
                                          placeholder="Text here..."
                                          rows="5"/>
                            </div>
                            <div className="form-group request-quote col-md-6">
                                <label htmlFor="notes">Extra Notes</label>
                                <textarea className="form-control" id="notes" name="notes" placeholder="Text here..."
                                          rows="5"/>
                            </div>
                        </div>
                        {/*<hr style={{background: "#B6B6B6", height: "1px", border: "none"}}/>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <p className="deliveryandshippingcost-text">Delivery & Shipping Cost</p>
                                <p className="VAT-text">VAT Included, where applicable</p>
                            </div>
                            <div className="form-group col-md-6" style={{textAlign: "right", lineHeight: "94px"}}>
                                <p className="cost-value-text">Rs. 0 </p>
                            </div>
                        </div>
                        <hr style={{background: "#B6B6B6", height: "1px", border: "none"}}/>*/}
                        {error.error &&
                        <div className="alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        {success.success &&
                        <div className="alert alert-success" role="alert">
                            {success.successMessage}
                        </div>}
                        <div style={{textAlign: "right"}}>
                            <p className="VAT-text">{list ? list.length : 0} Item(s)</p>
                            <div className="my-5" style={{display: "flex"}}>
                                <button className="btn btn-lg Pricetag blue-button" style={{marginLeft: "auto"}}
                                        type="submit" disabled={list ? list.length <= 0 : true}>
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Place QUOTE Request
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" fill="currentColor"
                                         className="bi bi-tag flip-tag-icon" viewBox="0 0 16 16">
                                        <path
                                            d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                                        <path
                                            d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default RequestQuotePage;
