import {useEffect, useState} from "react";

function IncDecCounter({number, updateNumber, index, max}) {
    let [num, setNum] = useState(number ? number : 0);

    useEffect(() => {
        if (num !== number) {
            setNum(number);
        }
    });

    let incNum = () => {
        if (max && !(num === max)) {
            setNum(Number(num) + 1);
            updateNumber(Number(num) + 1, index);
        }
    };

    let decNum = () => {
        if (num > 1) {
            setNum(Number(num) - 1);
            updateNumber(Number(num) - 1, index);
        }
    }

    let handleChange = (e) => {
        const num = e.target.value;
        if (num >= 0) {
            if (max && (num > max)) {
                setNum(Number(max));
                updateNumber(Number(max), index);
            } else {
                setNum(Number(num));
                updateNumber(Number(num), index);
            }
        } else {
            setNum(1);
            updateNumber(1, index);
        }
    }

    return (
        <>
            <div className="input-group d-flex flex-row">
                <div className="input-group-prepend">
                    <button className="btn" type="button" style={{
                        height: "4vh",
                        width: "4vh",
                        background: "#E0E0E0",
                        borderRadius: "6px 0px 0px 6px",
                        borderWidth: "0px"
                    }} onClick={decNum}>-
                    </button>
                </div>
                <div>
                    <input type="text" className="form-control" style={{
                        height: "4vh",
                        width: "10vh",
                        background: "##EFEFEF",
                        borderWidth: "0px",
                        textAlign: "center"
                    }} value={num} onChange={handleChange}/>
                </div>
                <div className="input-group-prepend">
                    <button className="btn " type="button" style={{
                        height: "4vh",
                        width: "4vh",
                        background: "#E0E0E0",
                        borderRadius: "0px 6px 6px 0px",
                        borderWidth: "0px"
                    }} onClick={incNum}>+
                    </button>
                </div>
            </div>
        </>
    );
}

export default IncDecCounter;
