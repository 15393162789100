import React from "react";
import AboutUsImagePasta from "../assets/images/aboutus-page-pasta.png";
import AboutUsImagePancake from "../assets/images/aboutus-page-pancake.png";

import '../assets/css/AboutUs.css';

function AboutUsPage() {
    return (
        <div style={{backgroundColor: "white", marginTop: "5vh"}}>
            <div className="d-flex justify-content-center mb-5 container about-us-container">
                <div className="container">
                    <h5 className="home-title mb-3 text-uppercase about-us-title">Home &gt; About Us</h5>
                    <h1 className="home-subtitle mb-5">About Us</h1>
                </div>
            </div>
            <div className="container about-us-detail-container">
                <div className="row" style={{margin: "10vh 0", alignItems: "center"}}>
                    <div className="col-12 col-md-4">
                        <h1>Our Story</h1>
                    </div>
                    <div className="col-12 col-md-8" style={{paddingLeft: "5%"}}>
                        <p className="para-text">
                            Founded in 1997, Mitshu Marketing Private Limited has emerged as a distinguished entity in
                            the industry. As a premier importer and distributor of the esteemed "MITSHU" brand in home
                            appliances, we have adeptly captured the trajectory of market development. Our company
                            boasts an extensive portfolio encompassing a diverse array of electrical and household
                            products.
                            <br/><br/>
                            We take pride in our commitment to deliver products of impeccable quality coupled with a
                            competitive pricing spectrum. To ensure adherence to these standards, we have implemented a
                            rigorous quality control framework aligned with the guidelines set by the Sri Lanka
                            Standards Institution. The provenance of our offerings stems from reputable sources, with
                            imports originating from prominent manufacturing hubs such as China, Malaysia, India, and
                            Thailand.
                        </p>
                    </div>
                </div>
                <div className="row" style={{margin: "15vh 0", alignItems: "center"}}>
                    <div className="col-12 col-md-4">
                        <img src={AboutUsImagePasta} alt="abtimg" style={{borderRadius: "6px", width: "100%"}}/>
                    </div>
                    <div className="col-12 col-md-8" style={{paddingLeft: "5%"}}>
                        <h1 className="my-5" style={{fontSize: "40px", lineHeight: "60px"}}>We’re On A <span
                            style={{color: "#194389"}}> Mission </span> To Provide You With The Best Products</h1>
                        <div>
                            <p className="para-text mb-4">
                                Introducing a new era of home appliances curated with a prime focus on delivering
                                efficiency and convenience. Our product range is a careful embodiment of robust
                                technology and inventive designs to lend a touch of sophistication to your space while
                                maximising functionality.
                            </p>
                        </div>
                        <div className="row mt-4" style={{textAlign: "center"}}>
                            <div className="col-12 col-md-4">
                                <p className="mb-0"
                                   style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1997</p>
                                <p className="para-text mb-3" style={{fontWeight: "500", textAlign: "center"}}>
                                    Start of Journey
                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p className="mb-0"
                                   style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1M+</p>
                                <p className="para-text mb-3"
                                   style={{fontWeight: "500", textAlign: "center"}}>Orders</p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p className="mb-0"
                                   style={{color: "#11448A", fontWeight: "700", fontSize: "56px"}}>1.5K+</p>
                                <p className="para-text mb-3" style={{fontWeight: "500", textAlign: "center"}}>Users</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{margin: "10vh 0vh", alignItems: "center"}}>
                    <div className="col-12 col-md-8" style={{paddingRight: "10%"}}>
                        <h1 className="my-5" style={{fontSize: "32px"}}>Our <span
                            style={{color: "#E1211D"}}>Vision</span> Is To Provide The Best Products At The Best Prices
                        </h1>
                        <p className="para-text mb-4">
                            We aim to provide remarkable value through our exceptional home appliances. Our products
                            blend the best of technology and functionality, bringing you top-notch quality at
                            cost-effective prices. We challenge the standard by making premium products within your
                            reach!
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <img src={AboutUsImagePancake} alt="abtpancake" style={{borderRadius: "6px", width: "100%"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage;
