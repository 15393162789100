import React, {useEffect, useState} from 'react';

import ProductDetails from "../components/ProductDetails";
import Loading from "../components/Loading";

import {loadProduct} from "../common/Common";

import '../assets/css/Product1.css';

function ProductPage({parentUpdateCount}) {
    const [productId] = useState(decodeURI(window.location.href.split('/').at(-1))),
        [product, setProduct] = useState(null),
        [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        loadProduct(productId).then(data => {
            setProduct(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    if (!loadingContent) {
        return (
            <div style={{backgroundColor: "white", marginTop: "10vh"}}>
                <ProductDetails product={product} parentUpdateCount={parentUpdateCount}/>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default ProductPage
