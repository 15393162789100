import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";

import {loadProductCategoryTags} from "../common/Common";

import Email2 from '../assets/images/Email2.png';
import Facebook2 from '../assets/images/Facebook2.png';
import Instagram2 from '../assets/images/Instagram2.png';
import Tiktok2 from '../assets/images/Tiktok2.png';
import Location from '../assets/images/Location.png';
import Phone2 from '../assets/images/Phone2.png';
import Logo from '../assets/images/logo.svg'
import paypal from '../assets/images/paypal1.png'
import visa from '../assets/images/visa1.png'
import master from '../assets/images/master.png'

import '../assets/css/Footer.css';

function Footer() {
    const [productCategoryTags, setProductCategoryTags] = useState([]);

    useEffect(() => {
        loadProductCategoryTags().then(data => {
            setProductCategoryTags(data);
        }).catch(() => null);
    }, []);

   {/*} const productCategoryTagBody = (productCategoryTags, maxCategoriesToShow) => {
        let body = [];
        productCategoryTags.forEach((productCategoryTag, index) => {
            body.push(
                <NavLink className="nav-link" to={'/products'}
                         state={{productCategoryTag: {id: productCategoryTag.id, name: productCategoryTag.name}}}
                         key={"FooterProductCategoryTag" + index}>
                    <h1 className="links">{productCategoryTag.name}</h1>
                </NavLink>
            );
        });
        return body;
    } */}

    const productCategoryTagBody = (productCategoryTags) => {
        let body = [];
        productCategoryTags.slice(0, 3).forEach((productCategoryTag, index) => {
            body.push(
                <NavLink className="nav-link" to={'/products'}
                         state={{productCategoryTag: {id: productCategoryTag.id, name: productCategoryTag.name}}}
                         key={"FooterProductCategoryTag" + index}>
                    <h1 className="links">{productCategoryTag.name}</h1>
                </NavLink>
            );
        });
        return body;
    }
    

    return (
        <footer>
            <div className="container">
                <div className="d-flex flex-column  mb-3">
                    <div className="p-2 flex-fill bd-highlight mb-3">
                        <img src={Logo} alt=""/>
                    </div>

                    <div className="p-2 flex-fill bd-highlight">
                        <div className="d-flex flex-row ">
                            <div className="flex-fill bd-highlight" style={{width: "25%"}}>
                                <div className=" GIT-container">
                                    <h1 className="subtitles-footer">Get in touch</h1>
                                    <div className="support-container">
                                        <p>
                                            <div className="d-flex">
                                                <img src={Location} alt="Location"/>
                                                <h1 className="links nav-link address">
                                                    89, 4-12,<br/> Bankshall Street,<br/>Colombo 11
                                                </h1>
                                            </div>
                                            <div className="d-flex">
                                                <img src={Phone2} alt="Phone2"/>
                                                <div>
                                                    <a href="tel:+9477 7226 225" className="nav-link"
                                                       style={{paddingBottom: 0}}>
                                                        <h1 className="links" style={{marginBottom: 0}}>
                                                            +9477 7226 225
                                                        </h1>
                                                    </a>
                                                    <a href="tel:+9411 2543 451" className="nav-link"
                                                       style={{paddingBottom: 0}}>
                                                        <h1 className="links" style={{marginBottom: 0}}>
                                                            +9411 2543 451
                                                        </h1>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <img src={Email2} alt="Email2"/>
                                                <a href="mailto:info@mitshu.lk" className="nav-link">
                                                    <h1 className="links">
                                                        info@mitshu.lk
                                                    </h1>
                                                </a>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 flex-fill bd-highlight" style={{width: "20%"}}>
                                <div className="Product-container">
                                    <h1 className="subtitles-footer">Categories</h1>
                                    <div className="support-container">
                                        <p className="text-start">
                                            {productCategoryTagBody(productCategoryTags)}
                                            <NavLink className="nav-link" to="/products">
                                                <h1 className="links">View More <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                 className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg></h1>
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 flex-fill bd-highlight" style={{width: "20%"}}>
                                <div className="Information-container">
                                    <h1 className="subtitles-footer">Information</h1>
                                    <div className="support-container">
                                        <p className="links">
                                            <NavLink className="nav-link" to="/about-us">
                                                <h1 className="links">About Us</h1>
                                            </NavLink>
                                            <NavLink className="nav-link" to="/contact-us">
                                                <h1 className="links">Contact Us</h1>
                                            </NavLink>
                                            <NavLink className="nav-link" to="/terms-and-conditions">
                                                <h1 className="links">Terms & Conditions</h1>
                                            </NavLink>
                                            <NavLink className="nav-link" to="/privacy-policy">
                                                <h1 className="links">Privacy Policy</h1>
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 flex-fill bd-highlight newsletter-container" style={{width: "25%"}}>
                                <div className="Subscribe-container">
                                    <h1 className="subtitles-footer mb-4">Subscribe to Newsletter</h1>
                                    <div className="support-container">
                                        <form className="d-flex mb-5">
                                            <input className="footer-searchbar" placeholder='Your Email Address'
                                                   type='email' id='newsletterEmail' aria-label="Email" required
                                                   style={{background: "#F1F1F1"}}/>
                                            <button className="btn btn-sm subscribe-btn blue-button" type="submit"
                                                    value="Submit">
                                                Subscribe
                                            </button>
                                        </form>
                                        <div className="d-flex flex-row mb-5 social-media-link-container">
                                            <div>
                                                <a className="nav-link" href="https://www.facebook.com/mitshu.lk"
                                                   target="_blank">
                                                    <img src={Facebook2} style={{width: "20px"}} alt='Facebook'/>
                                                </a>
                                            </div>
                                            <div>
                                                <a className="nav-link" href="https://instagram.com/mitshuinternational"
                                                   target="_blank">
                                                    <img src={Instagram2} style={{width: "20px"}} alt='Instagram'/>
                                                </a>
                                            </div>
                                            <div>
                                                <a className="nav-link" href="https://tiktok.com/"
                                                   target="_blank">
                                                    <img src={Tiktok2} style={{width: "15px"}} alt='TikTok'/>
                                                </a>
                                            </div>
                                        </div>

                                        {/* <div className="d-flex flex-row mb-5 social-media-link-container">
                                            <div>
                                                <img src={paypal} alt='paypal'/>
                                            </div>
                                            <div>
                                                <img src={master} alt='Instagram'/>
                                            </div>
                                            <div>
                                                <img src={visa} alt='TikTok'/>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <hr style={{border: "1px solid #B6B6B6"}}/>
                <div>
                    <p className="footer-end mt-4" style={{color: "#777777"}}>
                        All rights reserved | Mitshu International Trading LLC | {new Date().getFullYear()} | Website built by <a href='https://yellawt.com/' target='_blank'>Yellawt</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
