import React, {useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Header from './components/Header';

import HomePage from './views/HomePage';
import ProductPage from './views/ProductPage';
import ProductsPage from './views/ProductsPage';
import RequestQuotePage from './views/RequestQuotePage';
import AboutUsPage from './views/AboutUsPage';
import ContactUsPage from './views/ContactUsPage';
import TermsAndCondPage from './views/TermsAndCondPage';
import PrivacyPolicyPage from "./views/PrivacyPolicyPage";

import './App.css';

function App() {
    const [count, setCount] = useState(0);

    const updateCount = () => {
        setCount(count + 1);
    }

    return (
        <Router>
            <ScrollToTop>
                <div id="header">
                    <Header count={count} parentUpdateCount={updateCount}/>
                </div>
                <div id="headerandbody">
                    <Routes>
                        <Route exact path='/' element={<HomePage parentUpdateCount={updateCount}/>}/>
                        <Route path='/product/:productId' element={<ProductPage parentUpdateCount={updateCount}/>}/>
                        <Route path='/products' element={<ProductsPage/>}/>
                        <Route path='/search/:searchQuery' element={<ProductsPage/>}/>
                        <Route path='/request-quote'
                               element={<RequestQuotePage parentUpdateCount={updateCount} count={count}/>}/>
                        <Route path='/about-us' element={<AboutUsPage/>}/>
                        <Route path='/contact-us' element={<ContactUsPage/>}/>
                        <Route path='/terms-and-conditions' element={<TermsAndCondPage/>}/>
                        <Route path='/privacy-policy' element={<PrivacyPolicyPage/>}/>
                        <Route path='*' element={<Navigate to='/'/>}/>
                    </Routes>
                </div>
                <div id="footer">
                    <Footer/>
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
