import React from "react";

function PrivacyPolicyPage() {
    return (
        <div style={{backgroundColor: "white", marginTop: "5vh"}} className="d-flex justify-content-center">
            <div className="container term-and-condition-container" style={{width: "102vh"}}>
                <h1 className="mb-5 text-center">Privacy Policy</h1>
                <p className="para-text mb-3">
                    <strong>1. Introduction:</strong> MITSHU Marketing Private Limited ("MITSHU," "we," "us," or "our")
                    is committed to safeguarding your privacy and ensuring the security of your personal information.
                    This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you
                    access our website, use our services, and interact with us.
                </p>
                <p className="para-text mb-3">
                    <strong>2. Information We Collect:</strong> We may collect personal information that you voluntarily
                    provide to us, including but not limited to your name, contact details, email address, and other
                    relevant information necessary for providing our services. We may also collect non-personal
                    information such as browsing history, IP addresses, and cookies for analytical and technical
                    purposes.
                </p>
                <p className="para-text mb-3">
                    <strong>3. Use of Personal Information:</strong> We use your personal information to provide you
                    with our products, services, and information about our offerings. This includes processing orders,
                    responding to inquiries, and sending you relevant updates. We may also use your information for
                    marketing purposes, but you can opt-out of such communications.
                </p>
                <p className="para-text mb-3">
                    <strong>4. Sharing of Personal Information:</strong> We may share your personal information with
                    trusted third parties for the purpose of fulfilling orders, processing payments, and improving our
                    services. We do not sell or rent your personal information to third parties for marketing purposes.
                </p>
                <p className="para-text mb-3">
                    <strong>5. Data Security:</strong> We take appropriate measures to protect your personal information
                    from unauthorised access, loss, misuse, or alteration. Our data security measures include
                    encryption, secure protocols, and restricted access to your personal information.
                </p>
                <p className="para-text mb-3">
                    <strong>6. Cookies and Tracking Technologies:</strong> We use cookies and similar tracking
                    technologies to enhance your experience on our website. You can manage your cookie preferences
                    through your browser settings.
                </p>
                <p className="para-text mb-3">
                    <strong>7. Links to Third-Party Websites:</strong> Our website may contain links to third-party
                    websites. Please note that we are not responsible for the privacy practices or content of these
                    external sites. We encourage you to review their respective privacy policies.
                </p>
                <p className="para-text mb-3">
                    <strong>8. Children's Privacy:</strong> Our services are not intended for individuals under the age
                    of 18. We do not knowingly collect personal information from children. If you are a parent or
                    guardian and believe your child has provided us with personal information, please contact us to have
                    that information removed.
                </p>
                <p className="para-text mb-3">
                    <strong>9. Your Rights:</strong> You have the right to access, update, correct, or delete your
                    personal information. If you wish to exercise these rights, please contact us. We will respond to
                    your request within a reasonable timeframe.
                </p>
                <p className="para-text mb-3">
                    <strong>10. Changes to the Privacy Policy:</strong> We reserve the right to modify or update this
                    Privacy Policy at any time. Changes will be effective upon posting on our website. We encourage you
                    to review this Privacy Policy periodically for any updates.
                </p>
                <p className="para-text mb-3">
                    <strong>11. Contact Us:</strong> If you have any questions, concerns, or requests related to this
                    Privacy Policy, please contact our customer support team at <span
                    style={{color: "#194389"}}><a href="mailto:info@mitshu.lk" style={{
                    textDecoration: "none",
                    color: "#194389"
                }}>info@mitshu.lk</a></span>
                </p>
                <p className="para-text mb-3"><br/>
                    By using our website and services, you consent to the terms outlined in this Privacy Policy.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage;
