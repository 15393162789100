import {getRequest, postRequest} from "../routes/Routes";

export const loadTestimonials = async (limit = null) => {
    let testimonials = [];
    await getRequest(limit ? ('/api/testimonial/limit/' + limit) : '/api/testimonial').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                testimonials = response.data.testimonials;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return testimonials;
};

export const loadProductCategoryTags = async (limit = null) => {
    let productCategoryTags = [];
    await getRequest(limit ? ('/api/product-category-tag/limit/' + limit) : '/api/product-category-tag').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                productCategoryTags = response.data.productCategoryTags;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return productCategoryTags;
};

export const loadLocations = async (limit = null) => {
    let locations = [];
    await getRequest(limit ? ('/api/location/limit/' + limit) : '/api/location').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                locations = response.data.locations;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return locations;
};

export const loadCategories = async (limit = null) => {
    let categories = [];
    await getRequest(limit ? ('/api/category/limit/' + limit) : '/api/category').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                categories = response.data.categories;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return categories;
};

export const loadProductsFromTag = async (tag = null) => {
    let products = [];
    await getRequest('/api/product/tag/' + tag).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                products = response.data.products;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return products;
};

export const loadProduct = async (id = null) => {
    let product = null;
    await getRequest('/api/product/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                product = response.data.product;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return product;
};

export const loadProducts = async (limit = null) => {
    let products = [];
    await getRequest(limit ? ('/api/product/limit/' + limit) : '/api/product').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                products = response.data.products;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return products;
};

export const loadCountries = async () => {
    let countries = [];
    await getRequest('https://restcountries.com/v3.1/all?fields=name,flags', null).then(response => {
        if (response.status === 200) {
            countries = response.data;
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return countries;
};

export const loadSearchResults = async (searchQuery = null) => {
    let products = [];
    await getRequest('/api/search/' + searchQuery).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                products = response.data.results;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return products;
};

export const loadSearchFromProductCategoryTagsResults = async (values = {}) => {
    let products = [];
    values.endpoint = '/api/search/product-category-tags';
    await postRequest(values).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                products = response.data.results;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return products;
};

export const addProductToList = (product) => {
    let list = JSON.parse(sessionStorage.getItem('list'));
    if (list && list.length > 0) {
        let productIndex = list.findIndex(function (item) {
            return (item.id === product.id);
        });
        if (productIndex >= 0) {
            list[productIndex] = product;
        } else {
            list.push(product);
        }
    } else {
        list = [];
        list.push(product);
    }
    sessionStorage.setItem('list', JSON.stringify(list));
}

export const updateList = (list) => {
    sessionStorage.setItem('list', JSON.stringify(list));
}

export const calculateTotal = (list) => {
    let tempTotal = 0.00;

    if (list && list.length > 0) {
        list.forEach((product) => {
            tempTotal += product.price * product.selectedQuantity;
        });
    }

    return tempTotal;
} 
