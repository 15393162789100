import React from "react";

function TermsAndCondPage() {
    return (
        <div style={{backgroundColor: "white", marginTop: "5vh"}} className="d-flex justify-content-center">
            <div className="container term-and-condition-container" style={{width: "102vh"}}>
                <h1 className="mb-5 text-center">Terms And Conditions</h1>
                <p className="para-text mb-3">
                    <strong>1. Acceptance of Terms:</strong> By accessing and using the services provided by MITSHU
                    Marketing Private Limited ("MITSHU"), you agree to abide by these Terms and Conditions. These terms
                    outline the terms of use for our website and services.
                </p>
                <p className="para-text mb-3">
                    <strong>2. Intellectual Property:</strong> All content, including but not limited to text, images,
                    graphics, logos, and trademarks on the MITSHU website are the intellectual property of MITSHU or its
                    affiliates. Any unauthorised use, reproduction, or distribution of these materials is strictly
                    prohibited.
                </p>
                <p className="para-text mb-3">
                    <strong>3. Product Information:</strong> MITSHU strives to provide accurate and up-to-date
                    information about its products and services. However, we do not warrant the accuracy, completeness,
                    or reliability of the information provided on our website. Product specifications, availability, and
                    pricing are subject to change without prior notice.
                </p>
                <p className="para-text mb-3">
                    <strong>4. Product Warranty:</strong> MITSHU offers warranties on its products as specified in the
                    individual product documentation. Warranty coverage is subject to adherence to usage guidelines and
                    terms specified by MITSHU. Warranty claims must be made in accordance with the provided
                    instructions.
                </p>
                <p className="para-text mb-3">
                    <strong>5. Pricing and Payments:</strong> Product prices listed on the MITSHU website are in the
                    currency specified and are exclusive of applicable taxes and shipping charges. MITSHU reserves the
                    right to modify prices at any time. Payment for products and services is required in full at the
                    time of purchase.
                </p>
                <p className="para-text mb-3">
                    <strong>6. Shipping and Delivery:</strong> MITSHU aims to deliver products within the specified
                    timeframe. However, delivery times are estimates and subject to factors beyond our control. MITSHU
                    is not liable for any delays or damages incurred during shipping.
                </p>
                <p className="para-text mb-3">
                    <strong>7. Returns and Refunds:</strong> Products may be eligible for returns or exchanges as per
                    MITSHU's return policy. To initiate a return or exchange, please follow the provided instructions.
                    Refunds are subject to evaluation and compliance with the return policy.
                </p>
                <p className="para-text mb-3">
                    <strong>8. Limitation of Liability:</strong> MITSHU shall not be held liable for any direct,
                    indirect, incidental, consequential, or punitive damages arising from the use of our products,
                    services, or website. Use of our products is at your own risk.
                </p>
                <p className="para-text mb-3">
                    <strong>9. Governing Law:</strong> These Terms and Conditions are governed by the laws of Dubai,
                    UAE. Any disputes arising from the use of MITSHU's services shall be subject to the exclusive
                    jurisdiction of the courts in Dubai, UAE.
                </p>
                <p className="para-text mb-3">
                    <strong>10. Modification of Terms:</strong> MITSHU reserves the right to modify or update these
                    Terms and Conditions at any time without prior notice. It is your responsibility to review these
                    terms periodically for any changes.
                </p>
                <p className="para-text mb-3"><br/>
                    By using the MITSHU website and its services, you acknowledge that you have read, understood, and
                    agreed to these Terms and Conditions. If you do not agree with these terms, please refrain from
                    using our website and services.
                </p>
                <p className="para-text mb-5">
                    For any inquiries or concerns regarding these Terms and Conditions, please contact our customer
                    support team at <span
                    style={{color: "#194389"}}><a href="mailto:info@mitshu.lk" style={{
                    textDecoration: "none",
                    color: "#194389"
                }}>info@mitshu.lk</a></span>
                </p>
            </div>
        </div>
    )
}

export default TermsAndCondPage;
